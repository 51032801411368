import React, { useState, useEffect, useContext } from 'react'
import { useFirebase } from "gatsby-plugin-firebase"
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import numeral from 'numeral'

import Layout from '../components/Layout'

const Item = ({ data }) => {

    const [brand,setbrand] = useState({})
    const [images,setimages] = useState([])

    useFirebase(firebase => {
        if (data.brandId) {
            firebase.firestore().collection('brands').doc(data.brandId).get().then(snap => {
                setbrand(snap.data())
            })
        }
    },[data.brandId])

    useFirebase(firebase => {
        if (data.id) {
            firebase.firestore().collection('images').where('productId','==',data.id).orderBy('timestamp').get().then(snap => {
                const data = snap.docs.map(doc => doc.data())
                setimages(data)
            })
        }
    },[data.id])

    return (
        <Link css={css`
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        `} to={`/producto?id=${data.id}`}>
            <picture>
            {images[0] ?<img css={css`
                    height: 20rem;
                `} src={images[0].file} alt={data.name} /> : ''}
            </picture>
            <div css={css`
                padding: 1rem;
                text-align: center;
            `}>
                <h3 css={css`
                    color: var(--color-grey-dark-1);
                    font-size: 1.6rem;
                `}>{brand.name}</h3>
                <b css={css`
                    font-size: 1.6rem;
                    color: var(--color-grey-dark-1);
                    font-weight: 800;
                `}>{numeral(data.price).format('$0,0.00')}</b>
                <p css={css`
                    font-size: 1.4rem;
                    color: var(--color-grey-dark-3);
                `}>{data.name}</p>
            </div>
        </Link>
    )
}

export default () => {

    const [products,setproducts] = useState([])

    useFirebase(firebase => {
        firebase.firestore().collection('products').where('status','==','Activo').orderBy('brandId').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setproducts(data)
        })
    },[])

    // useEffect(() => {
    //     const firestore = firebase.firestore()
    //     firestore.collection('products').where('status','==','Activo').get().then(snap => {
    //         const data = snap.docs.map(doc => doc.data())
    //         setproducts(data)
    //     })
    // },[])

    return (
        <Layout>
            <div className="gallery" css={css`
                @media only screen and (max-width: 27em) {
                    padding-left: 0 !important;
                }
            `}>
                <div className="gallery__container">
                    <div className="content">
                        <h1 css={css`
                            @media only screen and (max-width: 27em) {
                                text-align: center;
                            }
                        `}>Gallery</h1>
                    </div>
                    <div css={css`
                        display: grid;
                        width: 100%;
                        margin: 0 auto;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 10px;
                        margin-top: 2.5rem;
                        @media only screen and (max-width: 27em) {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    `}>
                        {
                            products.map(item => <Item key={item.id} data={item} />)
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}